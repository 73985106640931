import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { auth } from "./firebase/init";
import { store } from "./store/store";

Vue.config.productionTip = false;

let app = null;

auth.onAuthStateChanged((user) => {
  if (user) {
    user.getIdTokenResult().then((idTokenResult) => {
      if (idTokenResult.claims.admin == true) {
        store.state.isAdmin = true;
      } else {
        store.state.isAdmin = false;
      }
    });
  } else {
    store.state.isAdmin = false;
  }

  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store: store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
