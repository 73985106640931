<template>
  <!-- App.vue -->

  <v-app>
    <Navigation v-if="navVisibility" />

    <v-main>
      <v-container fluid v-if="!minMode" style="max-width: 1200px;">
        <router-view></router-view>
      </v-container>

      <v-container style="max-width: 800px;">
        <router-view v-if="minMode"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
export default {
  name: "App",
  components: { Navigation },
  computed: {
    navVisibility() {
      return this.$store.state.navVisibility;
    },
    minMode() {
      return this.$store.state.minMode;
    },
  },
};
</script>
