import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    navVisibility: true,
    minMode: false,
    weeks: [],
    exerciseData: [],
    exercises: [],
    openExercises: [],
    points: 0,
    score: 0,
    containOpenExercises: false,
    doneTasks: [],
    scores: [],
    cards: [],
    currentCard: -1,
    currentExercise: -1,
    isAdmin: false,
    group: "",
    flashcards: [],
    uid: "",
  },
  mutations: {
    changeNavVisibility: (state, payload) => {
      state.navVisibility = payload;
    },
    changeMinMode: (state, payload) => {
      state.minMode = payload;
    },
    changeGroup: (state, payload) => {
      state.group = payload;
    },
    changeWeeks: (state, payload) => {
      state.weeks = payload;
    },
    changeUid: (state, payload) => {
      state.uid = payload;
    },
    changeExercises: (state, payload) => {
      state.exercises = payload;
    },
    pushCreatorCard: (state) => {
      state.currentCard++;
      state.cards.push({ nr: state.currentCard, exercises: [] });
    },
    pushCreatorExercise: (state, payload) => {
      state.currentExercise = state.currentExercise + 1;
      state.cards[payload.cardID].exercises.push(payload.exercise);
    },
    replaceCreatorExercise: (state, payload) => {
      state.cards[payload.cardID].exercises[payload.exerciseIndex] =
        payload.exercise;
    },
    replaceCards: (state, payload) => {
      state.cards = payload;
    },
    creatorAutoFixUp: (state) => {
      let exercisesCounter = 0;
      for (let i = 0; i < state.cards.length; i++) {
        // fix card nr prop
        state.cards[i].nr = i;

        if (state.cards[i].exercises) {
          // fix exercise id prop
          for (
            let exerciseI = 0;
            exerciseI < state.cards[i].exercises.length;
            exerciseI++
          ) {
            state.cards[i].exercises[exerciseI].id = exercisesCounter;
            exercisesCounter++;
          }
        }
      }

      state.currentExercise = exercisesCounter - 1;
      state.currentCard = state.cards.length - 1;
    },
    deleteLatestCard: (state) => {
      if (state.currentCard != 0) {
        state.cards.pop();
        state.currentCard = state.currentCard - 1;
        state.currentExercise =
          state.cards[state.currentCard].exercises[
            state.cards[state.currentCard].exercises.length - 1
          ].id;
      }
    },
    deleteLatestExercise: (state) => {
      if (state.currentExercise != -1) {
        state.cards[state.currentCard].exercises.pop();
        state.currentExercise = state.currentExercise - 1;
      }
    },
    changeScores: (state, payload) => {
      state.scores = payload;
    },
    changeDoneTasks: (state, payload) => {
      state.doneTasks = payload.doneExercises;
    },
    pushExerciseData: (state, payload) => {
      state.exerciseData.push(payload);
    },
    clearExerciseData(state) {
      state.exerciseData = [];
      state.points = 0;
      state.score = 0;
      state.openExercises = [];
      state.containOpenExercises = false;
    },
    incrementPoints(state) {
      state.points += 1;
    },
    incrementScore(state) {
      state.score += 1;
    },
    changeContainOpenExercise(state) {
      state.containOpenExercises = true;
    },
    setFlashcards(state, payload) {
      state.flashcards = payload;
    },
    addOpenExercise(state, payload) {
      state.openExercises.push(payload);
    },
  },
});
