<template>
  <div></div>
  <!-- <nav>
    <v-app-bar flat app dark id="toolbar" fixed>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer app dark v-model="drawer" id="nav">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> Portal </v-list-item-title>
          <v-list-item-subtitle> Pogotowie Ósmoklasisty </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="isRodoAdmin">
        <v-list-item link :to="{ name: 'ShowMembers' }">
          <v-list-item-icon>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Uczestnicy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Wyloguj</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-else>
        <v-list>
          <v-list-item link :to="{ name: 'Dashboard' }">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Ten tydzień</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'FlashcardsList' }">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Fiszki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'Help' }">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Pomoc</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Wyloguj</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list>
          <v-list-item link :to="{ name: 'Stats' }">
            <v-list-item-icon>
              <v-icon>mdi-chart-line-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Statystyki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'Archive' }">
            <v-list-item-icon>
              <v-icon>mdi-folder-open</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Archiwum</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'Creator' }" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Kreator</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </nav> -->
</template>

<script>
import firebase from "firebase";
import { mapState } from "vuex";

export default {
  computed: mapState(["isAdmin", "isRodoAdmin"]),
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.members = undefined;
          this.$router.replace({ name: "SignIn" });
        });
    },
  },
};
</script>

<style>
#nav {
  background: rgb(72, 0, 254);
  background: linear-gradient(
    0deg,
    rgba(72, 0, 254, 1) 0%,
    rgba(131, 58, 180, 1) 100%
  );
}
#toolbar {
  background-color: rgba(131, 58, 180, 1);
}
</style>
