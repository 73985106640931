import firebase from "firebase";

import Vue from "vue";
import VueRouter from "vue-router";

const Dashboard = () => import("../views/Dashboard.vue");
const Learning = () => import("../views/Learning.vue");
const StartLearning = () => import("../views/StartLearning.vue");
const NotFound = () => import("../views/NotFound.vue");
const Archive = () => import("../views/Archive.vue");
const Help = () => import("../views/Help.vue");
const SignIn = () => import("../views/SignIn.vue");
const FirstSignIn = () => import("../views/FirstSignIn.vue");
const Summary = () => import("../views/Summary.vue");
const Stats = () => import("../views/Stats.vue");
const NoStats = () => import("../views/NoStats.vue");
const Creator = () => import("../views/Creator.vue");
// FLASHCARDS
const Flashcards = () => import("../views/Flashcards.vue");
const FlashcardsList = () => import("../views/FlashcardsList.vue");
const FlashcardsShow = () => import("../views/FlashcardsShow.vue");

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: { name: "Dashboard" } },
  {
    path: "/dashboard/home",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: "Lista zadań – Portal Ósmoklasisty",
    },
  },
  {
    path: "/dashboard/creator",
    name: "Creator",
    component: Creator,
    meta: {
      requiresAuth: true,
      title: "Kreator – Portal Ósmoklasisty",
    },
  },
  {
    path: "/learning/:id",
    name: "Learning",
    props: true,
    component: Learning,
    meta: {
      // requiresAuth: true,
      title: "Tryb nauki – Portal Ósmoklasisty",
    },
  },
  // FLASHCARDS
  {
    path: "/flashcards/learning/:id",
    name: "Flashcards",
    props: true,
    component: Flashcards,
    meta: {
      // requiresAuth: true,
      title: "Tryb nauki (fiszki) – Portal Ósmoklasisty",
    },
  },
  {
    path: "/flashcards/list",
    name: "FlashcardsList",
    component: FlashcardsList,
    meta: {
      // requiresAuth: true,
      title: "Wybierz zestaw (fiszki) – Portal Ósmoklasisty",
    },
  },
  {
    path: "/flashcards/show/:id",
    name: "FlashcardsShow",
    props: true,
    component: FlashcardsShow,
    meta: {
      // requiresAuth: true,
      title: "Zapoznaj się z zestawem (fiszki) – Portal Ósmoklasisty",
    },
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
    meta: {
      requiresAuth: true,
      title: "Archiwum – Portal Ósmoklasisty",
    },
  },
  {
    path: "/stats",
    name: "Stats",
    component: Stats,
    meta: {
      requiresAuth: true,
      title: "Statystyki – Portal Ósmoklasisty",
    },
  },
  {
    path: "/no-stats-yet",
    name: "NoStats",
    component: NoStats,
    meta: {
      requiresAuth: true,
      title: "Statystyki (wkrótce) – Portal Ósmoklasisty",
    },
  },
  {
    path: "/dashboard/help",
    name: "Help",
    component: Help,
    meta: {
      requiresAuth: true,
      title: "Pomoc – Portal Ósmoklasisty",
    },
  },
  {
    path: "/learning-summary/:id",
    name: "Summary",
    component: Summary,
    props: true,
    meta: {
      // requiresAuth: true,
      title: "Tryb nauki (podsumowanie) – Portal Ósmoklasisty",
    },
  },
  {
    path: "/init",
    name: "StartLearning",
    component: StartLearning,
    meta: {
      title: "Uruchamiam zadanie...",
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    props: { goTo: "f" },
    component: SignIn,
    meta: {
      title: "Logowanie – Portal Ósmoklasisty",
    },
  },
  {
    path: "/onboarding",
    name: "FirstSignIn",
    component: FirstSignIn,
    meta: {
      title: "Pierwsze logowanie – Portal Ósmoklasisty",
    },
  },
  //404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser)
    next({ name: "SignIn", params: { goTo: to.path } });
  // else if (!requiresAuth && currentUser) next({ name: "Dashboard" });
  else next();
});

const DEFAULT_TITLE = "Portal Ósmoklasisty";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
